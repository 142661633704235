/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:24f6c3de-1f0f-419f-856d-6ffd0802156c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GU3ip9Pnp",
    "aws_user_pools_web_client_id": "20uudsd72up0dqi13q86u09vvr",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://4lryk7w4rzenjjn76mrwbu3ehi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "analitycsmobile8753a4c8bd154997b5e61ce3a19ab4da191525-master",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "bdc163246d8c4594bc2aa78d92cb1666",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
