/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVWComunicados = /* GraphQL */ `
  query GetVWComunicados($personaId: Int!) {
    getVWComunicados(personaId: $personaId) {
      comunicadoId
      titulo
      comunicado
      tipo
      archivo
      personaId
      nombre
      apellidoPaterno
      apellidoMaterno
      fechaInicio
      fechaFin
      leido
    }
  }
`;
export const listVWRolRutasSemanal = /* GraphQL */ `
  query ListVWRolRutasSemanal($personaId: Int!, $diasCalendario: String!) {
    listVWRolRutasSemanal(
      personaId: $personaId
      diasCalendario: $diasCalendario
    ) {
      id
      personaId
      persona
      rolId
      rol
      proyectoId
      proyecto
      horaEntrada
      rutaId
      ruta
      rutaFechaId
      diasCalendario
      actividad
      duracion
      prioridad
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      eventoId
      latitude
      longitude
      evento
      fechaLanzamiento
      ubicacionFisicaId
      ubicacion
      estado
      ciudad
      avance
      modoPrueba
      direccion
      aplicaGeocerca
      distanciaGeocerca
      cadenaId
      formatoId
      asistenciaConFoto
      fechaInicial
      fechaFinal
      porcentajeImagen
      editarFormularios
      requiereFirma
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
    }
  }
`;
export const listVWVersiones = /* GraphQL */ `
  query ListVWVersiones($aplicacion: String!, $dispositivo: String!) {
    listVWVersiones(aplicacion: $aplicacion, dispositivo: $dispositivo) {
      aplicacion
      dispositivo
      version
    }
  }
`;
export const listVWRolRutasFull = /* GraphQL */ `
  query ListVWRolRutasFull($personaId: Int!) {
    listVWRolRutasFull(personaId: $personaId) {
      id
      personaId
      persona
      rolId
      rol
      proyectoId
      proyecto
      horaEntrada
      rutaId
      ruta
      rutaFechaId
      diasCalendario
      actividad
      duracion
      prioridad
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      eventoId
      latitude
      longitude
      evento
      fechaLanzamiento
      ubicacionFisicaId
      ubicacion
      estado
      ciudad
      avance
      modoPrueba
      direccion
      aplicaGeocerca
      distanciaGeocerca
      cadenaId
      formatoId
      asistenciaConFoto
      fechaInicial
      fechaFinal
      porcentajeImagen
      editarFormularios
      requiereFirma
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
    }
  }
`;
export const listVWFormulariosFull = /* GraphQL */ `
  query ListVWFormulariosFull($personaId: Int!) {
    listVWFormulariosFull(personaId: $personaId) {
      formularioId
      formulario
      proyectoId
      proyecto
      rutaId
      rutaFechaId
      ruta
      tareaId
      completado
      tipo
      personaId
      obligatorio
      divisionId
      division
      descripcion
      tareasId
      cadenasId
      formaatosId
    }
  }
`;
export const listVWPreguntasFull = /* GraphQL */ `
  query ListVWPreguntasFull($personaId: Int!, $pagina: Int!) {
    listVWPreguntasFull(personaId: $personaId, pagina: $pagina) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVWProyectos = /* GraphQL */ `
  query ListVWProyectos($personaId: Int!) {
    listVWProyectos(personaId: $personaId) {
      proyectoId
      proyecto
      area
      rolId
      crearLugares
    }
  }
`;
export const listVWComunicados = /* GraphQL */ `
  query ListVWComunicados($personaId: Int!) {
    listVWComunicados(personaId: $personaId) {
      comunicadoId
      titulo
      comunicado
      tipo
      archivo
      personaId
      nombre
      apellidoPaterno
      apellidoMaterno
      fechaInicio
      fechaFin
      leido
    }
  }
`;
export const listVWPromotoria = /* GraphQL */ `
  query ListVWPromotoria($personaId: Int!) {
    listVWPromotoria(personaId: $personaId) {
      jefeId
      personaId
      persona
    }
  }
`;
export const listVWFormulariosCadenasFormatos = /* GraphQL */ `
  query ListVWFormulariosCadenasFormatos($personaId: Int!) {
    listVWFormulariosCadenasFormatos(personaId: $personaId) {
      formularioId
      cadenas
      formatos
    }
  }
`;
export const listVWFormulariosByRuta = /* GraphQL */ `
  query ListVWFormulariosByRuta($rutaFechaId: Int!) {
    listVWFormulariosByRuta(rutaFechaId: $rutaFechaId) {
      formularioId
      formulario
      proyectoId
      proyecto
      rutaId
      rutaFechaId
      ruta
      tareaId
      completado
      tipo
      personaId
      obligatorio
      divisionId
      division
      descripcion
      tareasId
      cadenasId
      formaatosId
    }
  }
`;
export const listVWPreguntasByRutaFormulario = /* GraphQL */ `
  query ListVWPreguntasByRutaFormulario(
    $rutaFechaId: Int!
    $formularioId: Int!
  ) {
    listVWPreguntasByRutaFormulario(
      rutaFechaId: $rutaFechaId
      formularioId: $formularioId
    ) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVWPreguntas = /* GraphQL */ `
  query ListVWPreguntas($personaId: Int!) {
    listVWPreguntas(personaId: $personaId) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVWPreguntasCadenasFormatos = /* GraphQL */ `
  query ListVWPreguntasCadenasFormatos($personaId: Int!) {
    listVWPreguntasCadenasFormatos(personaId: $personaId) {
      preguntaId
      cadenas
      formatos
    }
  }
`;
export const listVWPreguntasByRutas = /* GraphQL */ `
  query ListVWPreguntasByRutas($rutaFechaId: Int!, $pagina: Int!) {
    listVWPreguntasByRutas(rutaFechaId: $rutaFechaId, pagina: $pagina) {
      preguntaId
      formularioId
      preguntaPreviaId
      pregunta
      obligatorio
      fotoObligatoria
      cantidadFotos
      tipoDatoId
      tipoDato
      tipoDescripcion
      opciones
      imagen
      maximo
      minimo
      rutaId
      rutaFechaId
      personaId
      cadenas
      formatos
      colorProducto
      colorMarca
      marcaAgua
      rutasFechas
    }
  }
`;
export const listVWActividades = /* GraphQL */ `
  query ListVWActividades($personaId: Int!) {
    listVWActividades(personaId: $personaId) {
      actividadId
      actividad
      proyectoId
      censo
      requiereFirma
      rolId
      formulariosId
    }
  }
`;
export const listVWRolesRutasSinEventos = /* GraphQL */ `
  query ListVWRolesRutasSinEventos($personaId: Int!) {
    listVWRolesRutasSinEventos(personaId: $personaId) {
      id
      rolId
      rol
      proyectoId
      rutaId
      ruta
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      ubicacionId
      ubicacion
      ubicacionFisicaId
      ubicacionFisica
      ciudad
      estado
      direccion
      latitude
      longitude
      modoPrueba
      tipo
      zonaId
      cantidadDescansos
      censo
    }
  }
`;
export const listVWRolRutasMensual = /* GraphQL */ `
  query ListVWRolRutasMensual($personaId: Int!, $mes: Int!) {
    listVWRolRutasMensual(personaId: $personaId, mes: $mes) {
      id
      personaId
      persona
      rolId
      rol
      proyectoId
      proyecto
      horaEntrada
      rutaId
      ruta
      rutaFechaId
      diasCalendario
      actividad
      duracion
      prioridad
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      eventoId
      latitude
      longitude
      evento
      fechaLanzamiento
      ubicacionFisicaId
      ubicacion
      estado
      ciudad
      avance
      modoPrueba
      direccion
      aplicaGeocerca
      distanciaGeocerca
      cadenaId
      formatoId
      asistenciaConFoto
      fechaInicial
      fechaFinal
      porcentajeImagen
      editarFormularios
      requiereFirma
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
    }
  }
`;
export const listVWZonaLugares = /* GraphQL */ `
  query ListVWZonaLugares($zonaId: Int!) {
    listVWZonaLugares(zonaId: $zonaId) {
      id
      ciudad
      estado
      municipio
      tienda
      ubicacionFisica
      ubicacion
      latitude
      longitude
      tipo
    }
  }
`;
export const listVWCategorias = /* GraphQL */ `
  query ListVWCategorias {
    listVWCategorias {
      categoriaId
      categoria
    }
  }
`;
export const listVWCadenas = /* GraphQL */ `
  query ListVWCadenas {
    listVWCadenas {
      cadenaId
      cadena
    }
  }
`;
export const listVWFormatos = /* GraphQL */ `
  query ListVWFormatos {
    listVWFormatos {
      formatoId
      cadenaId
      formato
    }
  }
`;
export const listVWCanales = /* GraphQL */ `
  query ListVWCanales {
    listVWCanales {
      canalId
      canal
    }
  }
`;
export const listVWDirectorio = /* GraphQL */ `
  query ListVWDirectorio($personaId: Int!) {
    listVWDirectorio(personaId: $personaId) {
      empleado
      celular
      correo
      puesto
      foto
      area
    }
  }
`;
export const listVWCalendario = /* GraphQL */ `
  query ListVWCalendario($personaId: Int!, $anio: Int!, $mes: Int!) {
    listVWCalendario(personaId: $personaId, anio: $anio, mes: $mes) {
      fechaInicio
      fechaFin
      tipo
    }
  }
`;
export const listVWSinEventos = /* GraphQL */ `
  query ListVWSinEventos($personaId: Int!) {
    listVWSinEventos(personaId: $personaId) {
      personaId
      proyectoId
      tieneSinEventos
    }
  }
`;
export const listVWLugares = /* GraphQL */ `
  query ListVWLugares($personaId: Int!) {
    listVWLugares(personaId: $personaId) {
      personaId
      proyectoId
      rolId
      rol
      horaEntrada
      rutaId
      ruta
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      latitude
      longitude
      ubicacionFisicaId
      ubicacion
      ciudad
      estado
      modoPrueba
      direccion
      cadenaId
      formatoId
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
      actividadId
      actividad
      programado
      rutaFechaId
      rolRutaId
      aplicaGeocerca
      distanciaGeocerca
      porcentajeImagen
      editarFormularios
      requiereFirma
      asistenciaConFoto
      fechaInicial
      fechaFinal
      avance
      prioridad
      rutaFechaIdTemp
      persona
      foto
      celular
      correo
      area
      sucursal
      categoria
      nielsen
      nivelSocioeconomico
      horaInicio
      horaFin
      numeroTelefonico
      paginaWeb
      fotoInicio
      coordenadasInicio
      fotoFin
      coordenadasFin
      uuidAsistencia
    }
  }
`;
export const listVWTerritorios = /* GraphQL */ `
  query ListVWTerritorios(
    $personaId: Int!
    $proyectoId: Int!
    $fecha: String!
  ) {
    listVWTerritorios(
      personaId: $personaId
      proyectoId: $proyectoId
      fecha: $fecha
    ) {
      id
      nombre
      region
    }
  }
`;
export const listVWDistritos = /* GraphQL */ `
  query ListVWDistritos(
    $personaId: Int!
    $proyectoId: Int!
    $fecha: String!
    $terriorioId: Int!
  ) {
    listVWDistritos(
      personaId: $personaId
      proyectoId: $proyectoId
      fecha: $fecha
      terriorioId: $terriorioId
    ) {
      id
      nombre
    }
  }
`;
export const listVWLugaresSupervisados = /* GraphQL */ `
  query ListVWLugaresSupervisados(
    $proyectoId: Int!
    $fecha: String!
    $distritoId: Int!
  ) {
    listVWLugaresSupervisados(
      proyectoId: $proyectoId
      fecha: $fecha
      distritoId: $distritoId
    ) {
      personaId
      proyectoId
      rolId
      rol
      horaEntrada
      rutaId
      ruta
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      latitude
      longitude
      ubicacionFisicaId
      ubicacion
      ciudad
      estado
      modoPrueba
      direccion
      cadenaId
      formatoId
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
      actividadId
      actividad
      programado
      rutaFechaId
      rolRutaId
      aplicaGeocerca
      distanciaGeocerca
      porcentajeImagen
      editarFormularios
      requiereFirma
      asistenciaConFoto
      fechaInicial
      fechaFinal
      avance
      prioridad
      rutaFechaIdTemp
      persona
      foto
      celular
      correo
      area
      sucursal
      categoria
      nielsen
      nivelSocioeconomico
      horaInicio
      horaFin
      numeroTelefonico
      paginaWeb
      fotoInicio
      coordenadasInicio
      fotoFin
      coordenadasFin
      uuidAsistencia
    }
  }
`;
export const listVWLugaresByFecha = /* GraphQL */ `
  query ListVWLugaresByFecha($personaId: Int!, $fecha: String!) {
    listVWLugaresByFecha(personaId: $personaId, fecha: $fecha) {
      personaId
      proyectoId
      rolId
      rol
      horaEntrada
      rutaId
      ruta
      tiendaId
      tienda
      formato
      cadena
      canal
      ndeterminante
      latitude
      longitude
      ubicacionFisicaId
      ubicacion
      ciudad
      estado
      modoPrueba
      direccion
      cadenaId
      formatoId
      tipo
      zonaId
      cantidadDescansos
      censo
      formulariosId
      actividadId
      actividad
      programado
      rutaFechaId
      rolRutaId
      aplicaGeocerca
      distanciaGeocerca
      porcentajeImagen
      editarFormularios
      requiereFirma
      asistenciaConFoto
      fechaInicial
      fechaFinal
      avance
      prioridad
      rutaFechaIdTemp
      persona
      foto
      celular
      correo
      area
      sucursal
      categoria
      nielsen
      nivelSocioeconomico
      horaInicio
      horaFin
      numeroTelefonico
      paginaWeb
      fotoInicio
      coordenadasInicio
      fotoFin
      coordenadasFin
      uuidAsistencia
    }
  }
`;
export const getAsistencias = /* GraphQL */ `
  query GetAsistencias($id: ID!) {
    getAsistencias(id: $id) {
      id
      rolRutaId
      personaId
      fecha
      posicionInicial
      imagenInicial {
        bucket
        key
        region
      }
      fechaInicial
      posicionFinal
      imagenFinal {
        bucket
        key
        region
      }
      fechaFinal
      estatus
      createdAt
      updatedAt
      pathImagenInicial
      imagenFirma {
        bucket
        key
        region
      }
      modoPrueba
      modelo
      version
      pathImagenFinal
      pathImagenFirma
      estatusFinal
      tienda
      avance
      actividadId
      rutaId
      rutaFechaIdTemp
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAsistencias = /* GraphQL */ `
  query ListAsistencias(
    $id: ID
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAsistencias(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAsistencias = /* GraphQL */ `
  query SyncAsistencias(
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAsistencias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRespuestas = /* GraphQL */ `
  query GetRespuestas($id: ID!) {
    getRespuestas(id: $id) {
      id
      rolRutaId
      personaId
      preguntaId
      pregunta
      respuesta
      imagen {
        bucket
        key
        region
      }
      estatus
      answerId
      localizacion
      tareaId
      createdAt
      updatedAt
      modoPrueba
      modelo
      version
      pathImagen
      formularioId
      fecha
      actividadId
      rutaId
      rutaFechaIdTemp
      tareaIdTemp
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRespuestas = /* GraphQL */ `
  query ListRespuestas(
    $id: ID
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRespuestas(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRespuestas = /* GraphQL */ `
  query SyncRespuestas(
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRespuestas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDescansos = /* GraphQL */ `
  query GetDescansos($id: ID!) {
    getDescansos(id: $id) {
      id
      asistenciaId
      personaId
      proyectoId
      fechaInicial
      fechaFinal
      createdAt
      updatedAt
      modoPrueba
      modelo
      version
      fecha
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDescansos = /* GraphQL */ `
  query ListDescansos(
    $id: ID
    $filter: ModelDescansosFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDescansos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        asistenciaId
        personaId
        proyectoId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDescansos = /* GraphQL */ `
  query SyncDescansos(
    $filter: ModelDescansosFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDescansos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        asistenciaId
        personaId
        proyectoId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTiemposTareas = /* GraphQL */ `
  query GetTiemposTareas($id: ID!) {
    getTiemposTareas(id: $id) {
      id
      asistenciaId
      tareaId
      personaId
      fechaInicial
      fechaFinal
      createdAt
      updatedAt
      modoPrueba
      modelo
      version
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTiemposTareas = /* GraphQL */ `
  query ListTiemposTareas(
    $id: ID
    $filter: ModelTiemposTareasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTiemposTareas(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        asistenciaId
        tareaId
        personaId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTiemposTareas = /* GraphQL */ `
  query SyncTiemposTareas(
    $filter: ModelTiemposTareasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTiemposTareas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        asistenciaId
        tareaId
        personaId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByRolRuta = /* GraphQL */ `
  query ListAsistenciasByRolRuta(
    $rolRutaId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByRolRuta(
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByPersonaRolRuta = /* GraphQL */ `
  query ListAsistenciasByPersonaRolRuta(
    $personaId: String!
    $rolRutaId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByPersonaRolRuta(
      personaId: $personaId
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByPersona = /* GraphQL */ `
  query ListAsistenciasByPersona(
    $personaId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByPersona(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAsistenciasByPersonaFecha = /* GraphQL */ `
  query ListAsistenciasByPersonaFecha(
    $personaId: String!
    $fecha: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsistenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsistenciasByPersonaFecha(
      personaId: $personaId
      fecha: $fecha
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        fecha
        posicionInicial
        imagenInicial {
          bucket
          key
          region
        }
        fechaInicial
        posicionFinal
        imagenFinal {
          bucket
          key
          region
        }
        fechaFinal
        estatus
        createdAt
        updatedAt
        pathImagenInicial
        imagenFirma {
          bucket
          key
          region
        }
        modoPrueba
        modelo
        version
        pathImagenFinal
        pathImagenFirma
        estatusFinal
        tienda
        avance
        actividadId
        rutaId
        rutaFechaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByRolRuta = /* GraphQL */ `
  query ListRespuestasByRolRuta(
    $rolRutaId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByRolRuta(
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersonaRolRuta = /* GraphQL */ `
  query ListRespuestasByPersonaRolRuta(
    $personaId: String!
    $rolRutaId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersonaRolRuta(
      personaId: $personaId
      rolRutaId: $rolRutaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersona = /* GraphQL */ `
  query ListRespuestasByPersona(
    $personaId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersona(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByFormularioTarea = /* GraphQL */ `
  query ListRespuestasByFormularioTarea(
    $personaId: String!
    $rolRutaIdFormularioIdTareaId: ModelRespuestasByFormularioTareaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByFormularioTarea(
      personaId: $personaId
      rolRutaIdFormularioIdTareaId: $rolRutaIdFormularioIdTareaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersonaEstatus = /* GraphQL */ `
  query ListRespuestasByPersonaEstatus(
    $personaId: String!
    $estatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersonaEstatus(
      personaId: $personaId
      estatus: $estatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listRespuestasByPersonaFecha = /* GraphQL */ `
  query ListRespuestasByPersonaFecha(
    $personaId: String!
    $fecha: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRespuestasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespuestasByPersonaFecha(
      personaId: $personaId
      fecha: $fecha
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolRutaId
        personaId
        preguntaId
        pregunta
        respuesta
        imagen {
          bucket
          key
          region
        }
        estatus
        answerId
        localizacion
        tareaId
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        pathImagen
        formularioId
        fecha
        actividadId
        rutaId
        rutaFechaIdTemp
        tareaIdTemp
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDescansosByPersonaProyecto = /* GraphQL */ `
  query ListDescansosByPersonaProyecto(
    $personaId: String!
    $proyectoId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDescansosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDescansosByPersonaProyecto(
      personaId: $personaId
      proyectoId: $proyectoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asistenciaId
        personaId
        proyectoId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDescansosByByPersonaProyectoFecha = /* GraphQL */ `
  query ListDescansosByByPersonaProyectoFecha(
    $personaId: String!
    $proyectoIdFecha: ModelDescansosByPersonaProyectoFechaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDescansosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDescansosByByPersonaProyectoFecha(
      personaId: $personaId
      proyectoIdFecha: $proyectoIdFecha
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asistenciaId
        personaId
        proyectoId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDescansosByByPersonaFecha = /* GraphQL */ `
  query ListDescansosByByPersonaFecha(
    $personaId: String!
    $fecha: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDescansosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDescansosByByPersonaFecha(
      personaId: $personaId
      fecha: $fecha
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        asistenciaId
        personaId
        proyectoId
        fechaInicial
        fechaFinal
        createdAt
        updatedAt
        modoPrueba
        modelo
        version
        fecha
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
