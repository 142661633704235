/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:da7b06ea-201b-48e6-adb0-cb9f66407431",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w6G90KSbx",
    "aws_user_pools_web_client_id": "1nseo26dqiecq5tfn4ni1r8c9s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "49848bf5bf654ea99495297b73551b0b",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "49848bf5bf654ea99495297b73551b0b",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "49848bf5bf654ea99495297b73551b0b",
                "region": "us-east-1"
            }
        },
        "InAppMessaging": {
            "AWSPinpoint": {
                "appId": "49848bf5bf654ea99495297b73551b0b",
                "region": "us-east-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "apic1573c93",
            "endpoint": "https://g61s7iywq8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apiCtrlVersiones",
            "endpoint": "https://g3dvcfv9xj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6mxuad43enc5npc64hjgyp7upm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "ctrlplusappf788baccc8cc4804892acc756976ff15161013-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
